interactiveAreaInterval = null;

$(document).ready(function ($) {
	// screenshot slider
	if ($.fn.sudoSlider) {
		$('#video-container').sudoSlider({
			vertical: false,
			auto: true,
			autowidth: false,
			continuous: true,
			prevNext: false,
			numeric: false,
			slideCount: 1,
			speed: 250,
			responsive: true
		});
	}
});

function initInteractiveAreas() {
	$('.video-player-interactive-area').remove();
	$('.video-player-interactive-area-template').each(function() {
		var areaClone = $(this).clone();
		var rewindTo = parseInt($(this).attr('data-rewind-to'));
		areaClone.on('click', function() {
			$('.video-player-interactive-area').hide();
			jwplayer().seek(rewindTo);
		});
		areaClone.removeClass('video-player-interactive-area-template');
		areaClone.addClass('video-player-interactive-area');
		$('#video-container').append(areaClone);
	});
	if (interactiveAreaInterval) {
		clearInterval(interactiveAreaInterval);
	};
	interactiveAreaInterval = setInterval(function() {
		updateInteractiveAreas();
	}, 1000);
}

function updateInteractiveAreas() {
	$('.video-player-interactive-area').each(function() {
		var showSince = parseInt($(this).attr('data-show-since'));
		var showUntil = parseInt($(this).attr('data-show-until'));
		var position = jwplayer().getPosition();
		if (position) {
			if (showSince < position && showUntil > position) {
				$(this).show();
			} else {
				$(this).hide();
			}
		}
	});
}
